// COLORS
$primary-dark: #1d3557;
$primary-medium: #a8dadc;
$primary-light: #f0efeb;

$secondary-dark: #e63946;
$secondary-dark-transparent: #e6394644;
$secondary-light: #f1faee;

$landing-main: #c5dedd;
$border-radius: 4px;

@use "./variables" as *;
@use "./mixins" as *;

/* general reformatting */
*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  margin: 0;
}

p {
  margin: 0;
  font-size: 0.9rem;
}

a {
  text-decoration: none;
  color: $primary-dark;
}

/* general body styles */
body {
  font-family: "Source Sans Pro";
  margin: 0;
  color: $primary-dark;
}

@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.portal {
  flex-grow: 1;
  height: 100vh;
  position: relative;
  width: 100%;
  &__header {
    @include main-header;
  }
  &__heading {
    @include main-heading;
  }
  &__subheading {
    @include subheading;
  }
}

.in-portal {
  height: 100%;
  top: 5rem;
  left: 3rem;
}

@use "./variables" as *;

// breakpoints
@mixin breakpoint-large {
  @media (max-width: 1000px) {
    @content;
  }
}
@mixin breakpoint-medium {
  @media (max-width: 999px) {
    @content;
  }
}
@mixin breakpoint-small {
  @media (max-width: 568px) {
    @content;
  }
}

@mixin button-icon {
  background-color: inherit;
  width: fit-content;
  padding: 0;
  border: none;
  cursor: pointer;
}

@mixin icon {
  min-width: 1rem;
  width: 1rem;
  height: 1rem;
  border: 1px solid black;
  border-radius: 2px;
}

@mixin main-header {
  margin: 0;
  padding: 1rem 2rem;
  padding-top: 0.5rem;
  z-index: 1;
  color: $primary-dark;
  border-radius: $border-radius;
  display: flex;
  align-items: baseline;
  width: fit-content;
}

@mixin main-heading {
  margin: 0;
  margin-right: 1rem;
  font-size: 3rem;
}

@mixin subheading {
  font-size: 2.25rem;
  font-weight: 400;
  display: flex;
  align-items: flex-start;

  @include breakpoint-small {
    font-size: 1.25rem;
    font-weight: 600;
  }
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-Regular.woff2") format("woff2"),
    url("../../assets/fonts/SourceSansPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../assets/fonts/SourceSansPro-SemiBold.woff2") format("woff2"),
    url("../../assets/fonts/SourceSansPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.sideboard {
  position: fixed;
  top: 0;
  right: 0;
  padding: 0.75rem 2rem;
  padding-top: 0.75rem;
  z-index: 2;
  background-color: $secondary-dark-transparent;

  // box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  box-shadow: 3px 3px 0px #eee;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: auto;
  gap: 0.75rem;
  transition: margin-right 0.4s ease-in-out;

  &--minimized {
    padding: 0;
    margin: 1.75rem 2.5rem 0 0;
    border-radius: 50%;
  }
  &__button {
    @include button-icon;
  }

  // icon made in inkscape, not the same as other icons
  &__link-icon {
    padding-bottom: 2.5px;
    padding-left: 2px;
  }
  &__toggle-btn {
    border-radius: 50%;
    height: 1rem;
    margin-left: -1.5rem;
    background-color: $secondary-dark-transparent;
    align-self: center;
    cursor: pointer;
  }
  &__minimize-icon {
    @include icon;
    border: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 11rem;
  }

  &__heading {
    margin-left: 0.25rem;
  }

  &__add-form {
    width: 11rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  &__form-validity {
    order: 5;
    font-size: 0.75rem;
    margin-top: -0.75rem;
    color: red;
  }

  &__add-input {
    flex-grow: 1;
    width: 9rem;
    padding-left: 0.5rem;
    height: 24px;
    border: none;
    font-size: 0.75rem;
    border-radius: $border-radius;
  }
}

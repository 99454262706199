@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.toAdd {
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: 7rem;
  min-height: 3rem;
  max-width: 11rem;
}

.inPortal {
  position: absolute;
  max-height: 90%;
  max-width: 90%;
  border-radius: $border-radius;
  &:hover {
    outline: 1px solid #eee;
    transform: translate(-3px, -3px);
    box-shadow: 3px 3px 0px #eee;
  }
}

.element {
  padding: 4px;
  overflow: hidden;
  width: 11rem;
  &:hover {
    resize: both;
  }
  &__delete {
    visibility: hidden;
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;
    background-color: rgba(0, 0, 0, 0.01);
    font-size: 0.75rem;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    z-index: 2;
  }

  &__delete-icon {
    @include button-icon;
    width: 1rem;
  }
  &__image {
    width: 100%;
    pointer-events: none;
  }

  &__link {
    display: flex;
    width: fit-content;
    border-radius: $border-radius;
    border: 1px solid $primary-dark;
    padding: 0.1rem 1rem 0.3rem 1rem;
    justify-content: space-around;
    gap: 0.5rem;
  }
}

.element:hover .element__delete {
  visibility: visible;
}
